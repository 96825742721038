<template>
    <div id="comments" class="absolute top-0 h-full w-122 right-0 bg-filters shadow rounded-l-lg transition duration-500 ease-in-out z-50 " :style="(openedComments) ? 'transform:translateX(0%);' : 'transform:translateX(100%);'" v-click-outside="closeEvent">
        <div class="open-comments-btn header-btn rounded-l-full shadow-lg h-16 w-10 flex items-center justify-center cursor-pointer"
            @click="openedComments = !openedComments">
            <i class="mdi mdi-comment-multiple-outline text-aux">
            </i>
        </div>
        <comments-container class="h-full" @closeComents="closeEvent"/>
    </div>
</template>
<script>

    const CommentsContainer = () => import('@/components/Globals/CommentsContainer.vue')
    import ClickOutside from 'vue-click-outside'

    export default {
        components: {
            CommentsContainer
        },
        data() {
            return {
                openedComments: false
            }
        },
        methods: {
            closeEvent() { this.openedComments = false },
        },
        directives: { ClickOutside }
    }
</script>